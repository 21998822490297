import { ReactChildren } from '../../utils';

type TitleParams = {
    className?: string;
    children: ReactChildren;
};

export function H1(params: TitleParams) {
    return <h1 className={params.className}>{params.children}</h1>;
}

export function H2(params: TitleParams) {
    return <h2 className={params.className}>{params.children}</h2>;
}

export function H3(params: TitleParams) {
    return <h3 className={params.className}>{params.children}</h3>;
}

export function H4(params: TitleParams) {
    return <h4 className={params.className}>{params.children}</h4>;
}

export function H5(params: TitleParams) {
    return <h5 className={params.className}>{params.children}</h5>;
}

export function H6(params: TitleParams) {
    return <h6 className={params.className}>{params.children}</h6>;
}

type HTitleParms = TitleParams & {
    level: number;
};
export function H(params: HTitleParms) {
    const l = params.level;
    if (l === 1) {
        return <H1 className={params.className}>{params.children}</H1>;
    }
    if (l === 2) {
        return <H2 className={params.className}>{params.children}</H2>;
    }
    if (l === 3) {
        return <H3 className={params.className}>{params.children}</H3>;
    }
    if (l === 4) {
        return <H4 className={params.className}>{params.children}</H4>;
    }
    if (l === 5) {
        return <H5 className={params.className}>{params.children}</H5>;
    }
    return <H6 className={params.className}>{params.children}</H6>;
}

export const ContainerUnpadded = 'text-black flex flex-col';
export const Container = `p-[1em] ${ContainerUnpadded}`;

export const Filters = 'inline-flex pt-1 float-right';
export const Table =
    'container border border-separate border-spacing-0 border-primary-50 min-w-full bg-white rounded-sm';
export const TH =
    'py-3.5 pl-4 pr-3 text-left text-md font-bold text-gray-900 bg-primary-50 border-b border-b-primary-50 uppercase';
export const THFirst = `${TH} rounded-tl-sm`;
export const THLast = `${TH} rounded-tr-sm`;
export const TDLast = 'whitespace-nowrap px-4 py-4 text-sm text-black';
export const TD = `${TDLast} border-b border-b-primary-50`;
export const TR = 'hover:bg-background';

export const Pager = 'flex p-2 justify-center';
export const PagerLabel = 'm-1';
export const PagerSizes = 'p-2 float-left absolute';
export const PagerPages = 'flex h-8';

export const PagerButtonCommonStyles =
    'text-sm transition-colors py-1.5 px-3 border border-primary-50 hover:bg-primary-600 hover:text-white';
export const PagerButtonFirst = `${PagerButtonCommonStyles} bg-white text-black rounded-l`;
export const PagerButtonLast = `${PagerButtonCommonStyles} bg-white text-black rounded-r border-l-0`;
export const PagerButtonMiddle = (active?: boolean) =>
    `${PagerButtonCommonStyles} ${active ? 'font-bold text-primary-600 bg-primary-50' : 'font-normal text-black bg-white '
    } border-l-0 first:border-l`;

import * as TableStyles from '../../styles/TableStyles';
import * as FormStyles from '../../styles/FormStyles';
import React, { SyntheticEvent, useState } from 'react';
import SearchBar from '../form/SearchBar';
import Button, { buttonStyleClass } from '../../ButtonNew';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { H } from '../form/Titles';
import { ReactChildren } from '../../utils';

type PageHeaderParams = {
    title: string;
};

export function AdminPageHeader(params: PageHeaderParams) {
    return (
        <section className="bg-white px-16 py-4">
            <h1>{params.title}</h1>
        </section>
    );
}

type TableContainerParams = {
    children: ReactChildren;
    className?: string;
};

export function TableContainer(params: TableContainerParams) {
    return <section className={params.className ?? TableStyles.Container}>{params.children}</section>;
}

type TableHeaderParams = {
    title?: string;
    titleLevel?: number;
    createLink?: string;
    includeSearch?: boolean;
    searchText?: string;
    createText?: string;
    canCreate?: boolean;
    placeholder?: string;
    onSearch?: (text: string) => void;
    onCreate?: () => void;
};

export function TableHeader(params: TableHeaderParams) {
    const [searchText, setSearchText] = useState<string>(params.searchText ?? '');
    const { placeholder = 'Search' } = params;
    const searchChangedHandler = (text: string) => {
        setSearchText(text);
        if (params.onSearch) {
            params.onSearch(text);
        }
    };

    const createClickHandler = (evt?: SyntheticEvent) => {
        evt?.preventDefault();
        if (params.onCreate) {
            params.onCreate();
        }
    };

    const canCreate = params.canCreate === undefined ? true : params.canCreate;

    return (
        <>
            <section className="flex flex-row items-center justify-start rounded-t-sm border border-primary-50 bg-white p-2">
                {params.includeSearch && (
                    <section className="mr-4 pl-2">
                        <SearchBar
                            placeholder={placeholder}
                            className={FormStyles.SearchInput}
                            onChange={searchChangedHandler}
                            value={searchText}
                        />
                    </section>
                )}
                <div className="flex-grow">{params.title && <H level={params.titleLevel ?? 3}>{params.title}</H>}</div>
                {canCreate && params.createLink && (
                    <section className="p-4">
                        <Link className={buttonStyleClass({ buttonStyle: 'primary' })} to={params.createLink}>
                            <span>{params.createText ?? 'Create'}</span>
                        </Link>
                    </section>
                )}
                {canCreate && params.onCreate && (
                    <section className="p-4">
                        <Button buttonStyle="primary" buttonSize="sm" onClick={createClickHandler}>
                            <span className="flex flex-row items-center">{params.createText ?? 'Create'}</span>
                        </Button>
                    </section>
                )}
            </section>
        </>
    );
}

type TableHeaderWithSearchParams = {
    title?: string;
    createLink?: string;
    includeSearch?: boolean;
    searchText?: string;
    searchPlaceholder?: string;
    onSearch?: (text: string) => void;
};

export function TableHeaderWithSearch(params: TableHeaderWithSearchParams) {
    const [searchText, setSearchText] = useState<string>(params.searchText ?? '');
    const { searchPlaceholder: placeholder = 'Search' } = params;

    const searchChangedHandler = (text: string) => {
        setSearchText(text);
        if (params.onSearch) {
            params.onSearch(text);
        }
    };

    return (
        <>
            <section className="rounded-t-sm border-x border-t border-primary-50 bg-white px-4">
                <div className="flex w-full flex-row items-center justify-start border-b border-primary-50 py-4">
                    <div className="flex-grow">{params.title && <h3>{params.title}</h3>}</div>
                    {params.includeSearch && (
                        <section className="pl-2">
                            <SearchBar
                                placeholder={placeholder}
                                className={FormStyles.SearchInput}
                                onChange={searchChangedHandler}
                                value={searchText}
                            />
                        </section>
                    )}
                </div>
            </section>
        </>
    );
}

type TableParams = {
    children: ReactChildren;
    usePadding?: boolean;
};

export default function Table({ children, usePadding }: TableParams) {
    return (
        <section className={usePadding ? TableStyles.Container : TableStyles.ContainerUnpadded}>
            <table className={TableStyles.Table}>{children}</table>
        </section>
    );
}

type TableTHParams = {
    className?: string;
    children?: ReactChildren;
    isFirst?: boolean;
    isLast?: boolean;
};

export function TH(params: TableTHParams) {
    function findClass(): string {
        const classes: string[] = [];
        if (params.className) {
            classes.push(params.className);
        }
        if (params.isFirst) {
            classes.push(TableStyles.THFirst);
        } else if (params.isLast) {
            classes.push(TableStyles.THLast);
        } else {
            classes.push(TableStyles.TH);
        }
        return classes.join(' ');
    }

    const c = findClass();

    return (
        <th scope="col" className={c}>
            {params.children}
        </th>
    );
}

type TRParams = {
    children: ReactChildren;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

export function TR(params: TRParams) {
    function findClass(params: TRParams): string {
        const classes: string[] = [TableStyles.TR];
        if (params.className) {
            classes.push(params.className);
        }
        if (params.onClick) {
            classes.push('cursor-pointer');
        }

        return classes.join(' ');
    }
    const className = findClass(params);

    return (
        <tr onClick={params.onClick} className={className}>
            {params.children}
        </tr>
    );
}

type TDParams = React.TdHTMLAttributes<HTMLTableCellElement> & {
    children: ReactChildren;
    className?: string;
    colSpan?: number;
    isLast?: boolean;
    tooltipTitle?: string;
    onClick?: React.MouseEventHandler<HTMLTableCellElement>;
};

export function TD(params: TDParams) {
    function findClass(params: TDParams): string {
        const classes: string[] = [];
        if (params.className) {
            classes.push(params.className);
        }
        if (params.isLast) {
            classes.push(TableStyles.TDLast);
        } else {
            classes.push(TableStyles.TD);
        }

        return classes.join(' ');
    }

    const className = findClass(params);

    return (
        <td className={className} title={params.tooltipTitle} colSpan={params.colSpan}>
            {params.children}
        </td>
    );
}

type THeaderParams = {
    children: ReactChildren;
};

export function THead(params: THeaderParams) {
    return (
        <thead>
            <tr>{params.children}</tr>
        </thead>
    );
}

type TBodyParams = {
    className?: string;
    children: ReactChildren;
};

export function TBody(params: TBodyParams) {
    return <tbody className={params.className}>{params.children}</tbody>;
}

type TFootParams = {
    className?: string;
    children: ReactChildren;
};

export function TFoot(params: TFootParams) {
    return <tfoot className={params.className}>{params.children}</tfoot>;
}

export function SortedDown() {
    return (
        <span className="ml-2 flex-none rounded text-gray-900 group-hover:bg-gray-200">
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </span>
    );
}

export function SortedUp() {
    return (
        <span className="ml-2 flex-none rounded text-gray-900 group-hover:bg-gray-200">
            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        </span>
    );
}

export function SortedNot() {
    return (
        <span className="ml-2 flex-none rounded text-gray-900 group-hover:bg-gray-200">
            <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
        </span>
    );
}

export function displayDate(dt: string): string {
    return new Date(dt).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
}

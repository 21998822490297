import { useState } from 'react';

type SearchBarParams = {
    className?: string;
    placeholder?: string;
    onChange: (text: string) => void;
    value?: string;
};

export default function SearchBar(params: SearchBarParams) {
    const [searchText, setSearchText] = useState<string>(params.value ?? '');

    const textChangedHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(evt.target.value);
    };

    const submitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        params.onChange(searchText);
    };

    return (
        <form onSubmit={submitHandler}>
            <div className="relative float-right flex">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                        className="w-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        fill="none"
                        viewBox="0 0 20 20"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                    </svg>
                </div>
                <input
                    type="search"
                    className={`w-96 p-2 pl-10 ${params.className}`}
                    placeholder={params.placeholder}
                    value={searchText}
                    onChange={textChangedHandler}
                />
            </div>
        </form>
    );
}
